<template>
    <div> 
        <v-card color=""
            tile="" 
            flat="" :height="height"
            >  
            <!-- //NO SELECTED ITEMS -->
            <v-layout v-if="!MBS.actions.SIZE(SELECTED_ITEMS)" fill-height align-center justify-center>
                <v-chip large color="b1" >
                    <v-icon class="mx-5" color="grey">mdi-tag</v-icon>
                    <span class="grey--text  mr-5">
                        No Selected Item 
                    </span>
                </v-chip>
            </v-layout>
    
            <!-- //TABLE VIEW -->
            <div v-else-if="true"> 
                <v-simple-table  class="  mr-2 ml-4" dense :height="height" fixed-header >
                    <!-- <template v-slot:default>
                    </template> -->
                    <thead>  
                        <tr class="font-weight-bold "> 
                            <th class="ma-0 pa-0 " style="width:'5px'"> 
                                <v-switch :inset="false"    style="height: ; margin: 5px;"
                                    v-model="item_multiple_select"
                                    label=""   hide-details
                                    class="ml-1"
                                ></v-switch> 
                            </th>
                            <th class=" text-left b  ">
                                ITEM NAME
                            </th>
                            
                            <!-- <th class="text-center b  "> 
                                PRICE 
                            </th> -->
                            
                            <!-- <th class="text-right">
                                DISCOUNT
                            </th> -->
                            <th class="text-center  b  ">
                                QUANTITY  
                            </th>
                            <th class="text-center  ">
                                PRICE
                            </th>
                            <th class="text-center  b  "> 
                            </th> 

                        </tr>
                         
                        <!-- <v-divider /> -->
                    </thead>
                    <tbody> 
                        <tr 
                            v-for="(item,index) in SELECTED_ITEMS" 
                            :key="item.key" 
                            :class="(item.checked?'checked':'')+(item.key=='tip'?'b1 b2--text':'pointer')"
                            @click="ON_EDIT_SELECTED_ITEM(item,index)"
                            @mousedown="startLongClick"
                            @mouseup="endLongClick"
                            > 
                            <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                    v-if="item_multiple_select" icon 
                                    color="primary" 
                                    :value="item.checked" 
                                    @input="INPUT_UPDATE($event, item,true)" 
                                    />  
                                <span v-else icon >
                                    <v-icon v-if="item.expand">expand_more</v-icon>
                                    <v-icon v-else>chevron_right</v-icon>
                                </span>  
                            </td>
                            <td class=" with-divider ">
                                {{ item.name }} 
                                <span v-if="item.selected_item_option_names">
                                    ({{ item.selected_item_option_names }})
                                </span>
                            </td>
                            <!-- <td class=" text-right with-divider "> 
                                {{ item.sold_price }}
                            </td>   -->
                            <td class=" text-center pa-0 with-divider " style="width:100px;">
                                <!-- autofocus  -->
                                <input type="number"
                                    @click.stop="" 
                                    @input="INPUT_UPDATE($event, item,)" 
                                    ref="ref_payment_cash"
                                    id="payment_cash"
                                    :value="item.quantity"    
                                    class="mbs-excel-input"   
                                    @focus="is_payment_cash_focused=$event"
                                    @blur="is_payment_cash_focused=null"
                                />
                            </td>
                            <!-- <td class=" text-center with-divider ">
                                {{ item.quantity }}
                            </td> -->
                            <td class=" text-right with-divide">{{MBS.actions.money(CALCULATE_PRICE(item))}} </td>
                            <td class=" text-center with-divider-lr  px-2">
                                <v-btn icon class="ma-0 pa-0" @click.stop="CONFIRM_REMOVE_ITEM(item,index)"><v-icon>delete</v-icon></v-btn>
                            </td>
                        </tr>
                        <v-divider /> 
                        <!-- <tr class=""   
                            :class="' '" 
                            > 
                            <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;">  
                                <span  icon >
                                    <v-icon v-if="true">expand_more</v-icon>
                                    <v-icon v-else>chevron_right</v-icon>
                                </span>  
                            </td>
                            <td class=" with-divider ">
                                Tip 
                            </td> 
                            <td class=" text-center pa-0 with-divider " style="width:100px;">
                                <input type="number" disabled 
                                    @click.stop=""  
                                    ref="ref_payment_cash"
                                    :value="1"    
                                    class="mbs-excel-input"   
                                />
                            </td> 
                            <td class=" text-right with-divide">{{MBS.actions.money(CALCULATE_PRICE(1))}} </td>
                            <td class=" text-center with-divider-lr  px-2">
                                <v-btn icon class="ma-0 pa-0"  disabled><v-icon>delete</v-icon></v-btn>
                            </td>
                        </tr> -->
                        <v-divider />
                    </tbody>
                </v-simple-table> 
            </div> 
        </v-card>

        <!-- //DIALOGS -->
        <div>
            <!-- EDIT ITEM DIALOG -->
            <v-dialog
                v-model="dialog_edit_selected_item"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="800px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b1" v-if="current_selected_item" @click="LOG(current_selected_item)" >
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-tag</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ current_selected_item.name }}</v-list-item-title> 
                                <v-list-item-subtitle>{{ current_selected_item.category_name }}</v-list-item-subtitle>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-layout align-center >
                                    <div class="mr-1 not-f3 mt-2">MWK</div> 
                                    <span  class="not-f8 font-weight-bold"> 
                                        {{MBS.actions.money(CALCULATE_PRICE(current_selected_item))}} 
                                    </span>
                                </v-layout>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                        <form @submit.prevent="UPDATE_SELECTED_ITEM({
                            item:current_selected_item,
                            self_contain:true
                        })">
                            <v-card-text class="b1">
                                <v-row>
                                    <v-col cols="12" sm="6" class="py-0">  
                                        <div>{{ FindPricing(current_selected_item)?.name }}</div>
                                        <!-- <div >{{current_selected_item.pricing_tier?.name}}</div> ..dd -->
                                        <v-text-field dense color="primary"   
                                            :disabled="CompanySettings?CompanySettings.edit_selling_price?false:true:true"
                                            filled outlined v-mask="currencyMask"
                                            v-model="current_selected_item.sold_price"  
                                            outline
                                        ></v-text-field>  
                                    </v-col>

                                    <v-col cols="12" sm="6" class="py-0 " >
                                        <div>Quantity (QTY)</div>
                                            <v-text-field dense autofocus
                                                filled outlined 
                                                v-model="current_selected_item.quantity"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_quantity',
                                                ref:'ref_current_selected_item_quantity'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                     
                                    <v-col cols="12" sm="12" class="py-0" v-if="CompanySettings?CompanySettings.allow_selling_discount:false" >
                                        <div>Item Discount</div>
                                            <v-text-field dense  
                                                :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="current_selected_item.discount"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_discount',
                                                ref:'ref_current_selected_item_discount'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                    <!-- <v-col   cols="12" sm="6" class="py-0 " v-if="CompanySettings?CompanySettings.allow_selling_discount:false">
                                        <div>Discount Percent (%)</div>
                                            <v-text-field dense 
                                            :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="input_discount_percentage"
                                                outline
                                            ></v-text-field> 
                                    </v-col> -->
                                     
                                </v-row> 
                            </v-card-text> 

                             
                            <v-layout row wrap class="ma-0  mt-4">
                                <v-flex sm12 md6> 
                                    <v-card height="100%" hover class="mr-2 "> 
                                        <v-layout justify-center align-center fill-height class="mbs-border primary lighten-5  pa-2" :style="'--size:1px; --color:grey; --conner:3px'"> 
                                            <mbs-keyboard 
                                                :type="'number'"
                                                :row="'5'"
                                                :key_function="true"
                                                />
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-card height="100%" hover>
                                        <v-layout column  class="ma-0" fill-height justify-center align-center>  
                                            <v-spacer></v-spacer>
                                            <v-btn @click="CONFIRM_REMOVE_ITEM(current_selected_item)"
                                                class="my-2 font-weight-bold" 
                                                color="primary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    <div class="not-f5 mt-1">Remove Item</div> 
                                                </v-layout>
                                            </v-btn>  
                                            <v-btn type="submit"
                                                class="my-2 font-weight-bold"   
                                                color="secondary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">mdi-upload</v-icon>
                                                    <div class="not-f5 mt-1">Update Item</div> 
                                                </v-layout>
                                            </v-btn>   
                                        </v-layout> 
                                    </v-card>
                                </v-flex> 
                            </v-layout>   
                        </form>
                    </div> 
                </v-card> 
            </v-dialog> 
            
            <!-- ITEM OPTION DIALOG -->
            <v-dialog
                v-model="dialog_item_options"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="400px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b" v-if="current_selected_item">
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-palette-swatch</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ input_item_option?input_item_option.option_name:".." }}</v-list-item-title>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-btn icon @click="dialog_item_options=!dialog_item_options"><v-icon>close</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text>  
                        <v-list v-if="input_item_option?.option_type == 'multiple_selection'" class="pb-3"> 
                            <v-list-item  v-for="(item_option,index) in input_item_option?.item_options"  
                                :key="index"
                                @click="CHECK_SELECTED_ITEM_OPTION(item_option)"
                            >
                                <v-list-item-action >
                                    <v-icon v-if="IsOptionSelected(item_option)">mdi-checkbox-marked</v-icon> 
                                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon> 
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{item_option.option_name}}</v-list-item-title>  
                                </v-list-item-content>  
                            </v-list-item> 
                        </v-list>
                        <v-list v-else class="pb-3"> 
                            <v-list-item  v-for="(item_option,index) in input_item_option?.item_options"  
                                :key="index"
                                @click="ON_SELECT_ITEM_OPTIONS(selected_item_payload,item_option,[item_option])"
                            >
                                <!-- <v-list-item-action>
                                    <v-icon>mdi-check-box</v-icon> 
                                </v-list-item-action> -->
                                <v-list-item-content>
                                    <v-list-item-title>{{item_option.option_name}}</v-list-item-title>  
                                </v-list-item-content>  
                            </v-list-item> 
                        </v-list>
                    </v-card-text>
                    <v-card-actions v-if="input_item_option?.option_type == 'multiple_selection'">
                        <v-flex >
                            <v-btn @click="ON_SELECT_ITEM_OPTIONS(selected_item_payload,item_option,input_temporary_option_selected)" width="100%" class="mr-" color="primary">Continue</v-btn> 
                        </v-flex>
                        <v-btn @click="dialog_item_options=false" class="ml-1 px-5" color="secondary">Cancel</v-btn>
                    </v-card-actions>
                </v-card> 
            </v-dialog>  
        </div>
    </div>
</template> 
<script> 

    import DATA from "../../../plugins/DATA";
    import {mapState} from "vuex" 
    import DB from "../../../plugins/DB";
    import { toNumber } from "../../../plugins/formatNumber";
    const PAGE_NAME = "SELECT_ITEMS"

    export default {
        props:['height','selected_items','color','textColor','selling_model',
        'title','text','items','input_discount_amount','input_discount_percentage'],
        data(){
            return{
                show:false,   
                item_multiple_select:false,   
                dialog_edit_selected_item:false,   
                dialog_item_options:false,   
                current_selected_item:null,   
                input:{},   
                focused_field:null,
                // input_discount_amount:null,   
                // input_discount_percentage:null,   
                longClickTimer: null,
                longClickDetected: false,
                clearing_sales: false,

                
                selectedRows: [],
                lastSelectedRow: -1,
                editingCell: null,
                input_item_option:null,
                input_selected_item_option:[],
                input_temporary_option_selected:[],
                is_payment_cash_focused:null

            }
        },
        created(){ 
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN); 
                this.MBS.events.$on('ADD_SELECTED_SELLING_ITEMS', this.ADD_SELECTED_SELLING_ITEMS); 
                // this.CLEAR_SALE_ORDERS(this.LocalCashup)
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){  
            this.OUTPUT() 
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
               
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()], 
                
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
            }), 

            //data
            ItemsData(){
                const Items = this.Items
                const JoinedItems = this.JoinedItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            LocalSelectedSalesItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedSalesItemsData(){   
                const items = this.LocalSelectedSalesItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            }, 
            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            },
            FindPricing(){
                const prices = this.AllPricingTiers
                return item=>{
                    let pricing_tier = item?.pricing_tier
                    let pricing_tier_key = item?.pricing_tier_key
                    if (pricing_tier) {
                        pricing_tier_key = pricing_tier?.key
                    }
                    if(!prices){return null}
                    return prices.find(item=>item.key==pricing_tier_key)
                }
            },

            CheckedItems(){
                const SELECTED_ITEMS = this.SELECTED_ITEMS
                if(!SELECTED_ITEMS){return null}
                let filtered = SELECTED_ITEMS.filter(item=>item.checked)
                return filtered
            },

            SELECTED_ITEMS(){
                const settings = this.CompanySettings
                const selling_list_descend = settings?settings.selling_list_descend:null
                const items = this.LocalSelectedSalesItemsData 
                const reversed = this.ReversedLocalSelectedSalesItemsData   
                return selling_list_descend?reversed:items
            },
             
            //pricing
            CalculateItemsPrice(){
                return (items)=>{ 
                    try {   
                        let total_price = 0 
                        if (!items) {return 0}
                        items.forEach(item => {
                            let sold_price = this.toNumber(item.sold_price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(sold_price-discount))
                        });  
                        // return total_price
                        return total_price.toFixed(2) 
                        
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'TotalPrice',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    }  
                }
            },
            TotalPrice(){
                try { 
                    // if (this.SELECTED_CARD_OPTION=='load_money') { 
                    //    return this.toNumber(this.pay_order?this.pay_order.card_load_amount:0)
                    // } else {
                    // }
                    let items = this.SELECTED_ITEMS
                    let total_price = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let sold_price = this.toNumber(item.sold_price)
                        let discount = this.toNumber(item.discount)
                        let quantity = this.toNumber(item.quantity)
                        total_price = total_price + (quantity*(sold_price-discount))
                    });  
                    // return total_price
                    return total_price.toFixed(2) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TotalDiscount(){
                try { 
                    let amount = this.toNumber(this.input_discount_amount)
                    let percentage = this.toNumber(this.input_discount_percentage)
                    let total = this.toNumber(this.TotalPrice)
                    if (percentage) {
                        return (total*percentage/100)
                    } 
                    return amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },
            TotalDiscountedPrice(){
                try {  
                    let discount = this.toNumber(this.TotalDiscount)
                    let CalculatedVAT = this.CalculatedVAT
                    let total_vat = this.toNumber(CalculatedVAT?CalculatedVAT.total_vat:0)
                    let total_price = this.toNumber(this.TotalPrice)
 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null

                    if (items_are_vat_exclusive && calculate_vat) {
                        total_price = total_price+total_vat
                    }
                     
                    return total_price-discount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            CalculatedVAT(){
                try { 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null
                    const SELECTED_ITEMS = this.SELECTED_ITEMS
                    if(!calculate_vat){return null}
                    let vat = {
                        taxable_a:0,
                        vat_a:0,
                        taxable_b:0,
                        vat_b:0,
                        taxable_c:0,
                        vat_c:0,
                        total_vat:0,
                        vat_exclusive:items_are_vat_exclusive
                    }
                    if(SELECTED_ITEMS){ 
                        SELECTED_ITEMS.forEach(item => {
                            let tax_group_code = item.tax_group_code?item.tax_group_code:"A"
                            let sold_price = this.toNumber(item.sold_price)*this.toNumber(item.quantity)
                            
                            let item_vat = 0
                            
                            if (tax_group_code=="A") {
                                vat.taxable_a = vat.taxable_a + sold_price
                                if (items_are_vat_exclusive) {
                                    item_vat = sold_price*16.5/100
                                } else {
                                    let not_vat_price = sold_price / (1+(16.5/100))
                                    item_vat = sold_price-not_vat_price 
                                }
                                vat.vat_a = vat.vat_a+item_vat
                            }if (tax_group_code=="B") {
                                vat.taxable_b = vat.taxable_b + sold_price
                            }if (tax_group_code=="E") {
                                vat.taxable_c = vat.taxable_c + sold_price
                                
                            }
                            
                        }); 
                        vat.total_vat = vat.vat_a + vat.vat_b+vat.vat_c
                        if (!items_are_vat_exclusive) {
                            vat.taxable_a = vat.taxable_a - vat.vat_a
                            vat.taxable_b = vat.taxable_b - vat.vat_b
                            vat.taxable_c = vat.taxable_c - vat.vat_c
                        }
                    } 
                    return vat
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            PriceExcludeVAT(){
                try { 
                    const settings = this.CompanySettings
                    const items_are_vat_exclusive = settings?settings.items_are_vat_exclusive:null
                    const calculate_vat = settings?settings.calculate_vat:null
                    return (price,tax_group_code)=>{
                        price = this.toNumber(price) 
                        if (calculate_vat && !items_are_vat_exclusive) {
                            if (tax_group_code=="B"||tax_group_code=="E") {
                                return price
                            }
                            return price / (1+(16.5/100))
                        }
                        return price

                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PriceExcludeVAT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            
            IsOptionSelected(){
                try { 
                    let input_temporary_option_selected = this.input_temporary_option_selected
                    return (item_option)=>{ 
                        let selected = input_temporary_option_selected.find(op=>op.option_name == item_option.option_name)
                        return selected 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'IsOptionSelected',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },   

            
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            },
            OutletsData(){
                return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
            }, 
            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData
                let cashup = this.LocalCashup  
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                }) 
                return filled
            },
            CurrentOutlet(){
                let outlets = this.OutletsData
                let till = this.CurrentTill   
                if(!outlets || !till){return null}
                let filled = outlets.find(outlet=>outlet.code==till.outlet_code)
                return filled
            }, 
        },
        methods:{ 
            LOG(event){
                console.log(event,'event......');
            },
            handleKeyDown(event) {
                // if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                //     event.preventDefault();
                // }
            },
            isEditing(rowIndex, columnIndex) {
            return this.editingCell && this.editingCell.row === rowIndex && this.editingCell.column === columnIndex;
            },
            startEditing(rowIndex, columnIndex) {
            this.editingCell = { row: rowIndex, column: columnIndex };
            this.$nextTick(() => this.$refs.editField.focus());
            },
            stopEditing(rowIndex, columnIndex) {
            this.editingCell = null;
            },
            selectRow(index) {
                if (this.lastSelectedRow !== -1 && this.$root.shiftKey) {
                    const start = Math.min(this.lastSelectedRow, index);
                    const end = Math.max(this.lastSelectedRow, index);
                    this.selectedRows = [];

                    for (let i = start; i <= end; i++) {
                    this.selectedRows.push(i);
                    }
                } else if (this.selectedRows.includes(index)) {
                    this.selectedRows.splice(this.selectedRows.indexOf(index), 1);
                } else {
                    this.selectedRows.push(index);
                } 
                this.lastSelectedRow = index;
            },


            startLongClick() {
                this.longClickTimer = setTimeout(() => {
                    this.longClickDetected = true;
                    this.item_multiple_select = !this.item_multiple_select; 
                }, 1000); 
            },
            endLongClick() {
                clearTimeout(this.longClickTimer);
                setTimeout(() => { 
                    this.longClickDetected = false;
                }, 10);
            },
            async CLEAR_SALE_ORDERS(LocalCashup){
                try {   
                    let day_shift_key = this.LocalCashup?.day_shift_key
                    let cashup_key = this.LocalCashup?.key
                    if (!cashup_key) {return}
                    if(this.clearing_sales){
                        setTimeout(() => {
                           this.CLEAR_SALE_ORDERS(LocalCashup) 
                        }, 10000);
                    }else{
                        this.clearing_sales = true
                        let res_local_sales = await DB.getItems({
                            name:DATA.ITEMS.LOCAL_SALE_ORDERS.values
                        })
                        let local_sales_orders = res_local_sales?.data
                        let delete_orders = local_sales_orders?local_sales_orders.filter(order=>{
                            return order.uploaded && order.cashup_key!=cashup_key
                        }):null
                        if (this.MBS.actions.SIZE(delete_orders)) { 
                            let res =  await DB.deleteItems({ 
                                name:DATA.ITEMS.LOCAL_SALE_ORDERS.values, 
                                update_locals:true, 
                                items:delete_orders
                            }) 
                        } 
                        this.clearing_sales = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    const sold_price = this.toNumber(item.sold_price)
                    const discount = this.toNumber(item.discount)
                    const quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_EDIT_SELECTED_ITEM(item){
                try { 
                    // if ( this.item_multiple_select) {
                    //     let index = this.SELECTED_ITEMS.findIndex(it=>it.selecting_list_key = item.selecting_list_key)
                    //     this.$set(this.SELECTED_ITEMS, index, { check: item.check?false:true});
                    //     return
                    // }
                    if (item?.key=='tip') {
                        return
                    }
                    if (!this.longClickDetected) {
                        this.current_selected_item = null 
                        setTimeout(() => {
                            this.dialog_edit_selected_item = true
                            this.current_selected_item = {...item}   
                        }, 30);
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EDIT_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
            CHECK_SELECTED_ITEM_OPTION(item_option){
                try { 
                    let option_name = this.MBS.actions.TEXT_UP(item_option?.option_name)
                    let index = this.input_temporary_option_selected.find(op=>this.MBS.actions.TEXT_UP(op?.option_name) == option_name)
 
                    if(!index){
                        this.input_temporary_option_selected.push(item_option) 
                    }else{
                        this.input_temporary_option_selected = [...this.input_temporary_option_selected.filter(op=>op.option_name != item_option.option_name)]
                    
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EDIT_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            ON_SELECT_ITEM_OPTIONS(payload,input_item_option,selected_item_options){
                try {
                    this.selected_item_payload = payload
                    let item = payload?.item
                    let item_options = input_item_option
                    if (!input_item_option && !selected_item_options) {
                        this.input_selected_item_option = []
                        let options = item?.item_options
                        item_options = {
                            item_options:options,
                            option_name:"Select Item Option"
                        }
                    }

                    if (selected_item_options) {
                        this.input_selected_item_option.push(...selected_item_options)
                    } 
                    // 
                    this.dialog_item_options = false
                    if (item_options?.item_options) {
                        setTimeout(() => { 
                            this.dialog_item_options = true
                            this.current_selected_item = item
                            this.input_item_option = item_options
                            this.input_temporary_option_selected = []
                        }, 100);  
                    } else {
                        this.SUBMIT_SELECTED_SELLING_ITEMS(item,this.input_selected_item_option)
                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_ITEM_OPTIONS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            CONFIRM_REMOVE_ITEM(selected_item,index){
                try { 
                    // this.$emit("confirm_remove_item",selected_item,index)
                    const settings = this.CompanySettings
                    const require_void_to_remove_item = settings?settings.require_void_to_remove_item:null  
                    
                    this.dialog_edit_selected_item = false 
                    if (require_void_to_remove_item) {
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-shield-lock-outline",
                            title:"VOID REQUIRED TO REMOVE ITEM(S)",
                            text:"Removing item(s) require admin permission. Enter your admin phone, and password to authorize this action.",
                            action:{
                                code:PAGE_NAME+'=REMOVE-ITEM',
                                data:selected_item,
                                all_users:true, 
                                get_access:{
                                    permission_type:"action",
                                    permission_to:"delete",
                                    action_name:DATA.ITEMS.SALE_ORDER_ITEMS.names 
                                }
                            }
                        }) 
                    } else {
                        this.REMOVE_ITEM(selected_item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(data){
                try { 
                    // this.$emit("remove_item",data)
                    let company = this.SELECTED_COMPANY
                    let company_key = company?company.company_key:null
                     
                    DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
                        key:data.key,
                        update_locals:true,
                        // company_key:company_key, 
                    }).then(res=>{ 
                    }).catch(error=>{
                        console.log(error,'error...........');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            FOCUS_INPUT(field){
                this.focused_field = field 
            },
            INPUT_UPDATE(event,item,check_item){
                try { 
                    let target = event?event.target:null
                    let value = target?target.value:null
                    if(check_item){
                        item = {
                            ...item,
                            checked:event?true:false
                        }
                        value = item.quantity
                    }
                    this.UPDATE_SELECTED_ITEM({
                        item:item,
                        quantity:value,
                        discount:item.discount,
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INPUT_UPDATE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_SELECTED_ITEM(payload){
                try {
                    this.dialog_edit_selected_item = false
                    let item = payload?payload.item:null
                    let quantity = payload?payload.quantity:null
                    let discount = payload?payload.discount:null
                    let self_contain = payload?payload.self_contain:null
                    if (self_contain) {
                        quantity = item.quantity
                        discount = item.discount
                    }

                    let res = await DB.addItems({
                        data:{
                            ...item,
                            // quantity:this.toNumber(quantity),
                            quantity:quantity,
                            discount:this.toNumber(discount)
                        },
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        update_locals:true,   
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async UPDATE_AUTO_TIP(){
                try { 
                    const outlet = this.CurrentOutlet
                    const add_auto_tip = outlet?.add_auto_tip
                    const add_auto_tip_name = outlet?.add_auto_tip_name?outlet?.add_auto_tip_name:'Tip'
                    const auto_tip_percentage = toNumber(outlet?.auto_tip_percentage)
                    const auto_tip_amount = toNumber(outlet?.auto_tip_amount)
                    const tax_group_code = 'B'

                    let all_items = this.LocalSelectedSalesItemsData
                    let items = all_items?all_items.filter(item=>item.key !='tip'):null
                    let tip_item = all_items?all_items.find(item=>item.key == 'tip'):null
                    let tip_item_sold_price = toNumber(tip_item?.sold_price)
                    

                    if (this.MBS.actions.SIZE(all_items)==1) {
                        if (tip_item) {
                            let res = await DB.deleteItems({ 
                                key:'tip', 
                                name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                                update_locals:true,   
                            }) 
                        } 
                    }
                    
                    if (add_auto_tip&&this.MBS.actions.SIZE(all_items)) {
                        let tip_price = 0
                        let total_sold_price = 0
                        if (auto_tip_percentage) {
                            items.forEach(item => {
                                total_sold_price = total_sold_price+this.toNumber(item.sold_price)
                            }); 
                            tip_price = total_sold_price*auto_tip_percentage/100
                        }else if(auto_tip_amount){
                            tip_price = auto_tip_amount
                        }
                        if (!tip_item) {
                            let res = await DB.addItems({
                                data:{
                                    name:add_auto_tip_name,
                                    key:'tip',
                                    code:'tip',
                                    sold_price:tip_price,
                                    tax_group_code:tax_group_code,
                                    quantity:this.toNumber(1),
                                    discount:this.toNumber(0)
                                },
                                name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                                update_locals:true,   
                            }) 
                        }else if (tip_item && (tip_item_sold_price!=tip_price)) {
                            let res = await DB.updateItems({
                                data:{ 
                                    key:'tip', 
                                    sold_price:tip_price, 
                                },
                                name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                                update_locals:true,   
                            }) 
                        } 
                    }else {
                        if (tip_item) {
                            let res = await DB.deleteItems({ 
                                key:'tip', 
                                name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                                update_locals:true,   
                            }) 
                        } 
                    }

                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_AUTO_TIP',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            
            ANALYZE_AND_SELECT_ITEM(item_key, item_code){
                try { 
                    let company_key = this.SELECTED_COMPANY?this.SELECTED_COMPANY.key:null
                    const settings = this.CompanySettings 
                    const activate_stock_level = settings?settings.activate_stock_level:null
                    const duplicate_selling_item = settings?settings.duplicate_selling_item:null


                    const till = this.CurrentTill
                    const outlet_code = till?till.outlet_code:null
                    let quantity = null
                    let retail_price = null
                    let wholesale_price = null
                    let scales = this.Scales  
                    
                    const items = this.ItemsData
                    const selling_model = this.selling_model
                    const moment = this.MBS.date.moment
                    const selecting_list_key = moment?moment().valueOf():null

                    
                    if (!selling_model) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"SELECT SELLING",
                            text:"You must select selling model first",
                            btnYes:"Okay",
                            action:{
                                code:PAGE_NAME+"=SELECT-SELLING-MODEL"
                            }
                        })
                        return
                    }
                    if (items) {
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })

                        // scale items
                        if (!item_selected && item_code && scales) {
                            scales.forEach(scale => {
                                let prefix = scale?scale.barcode_prefix:null
                                let prefix_size = this.MBS.actions.SIZE(prefix)
                                let c_size = scale?scale.decimal_length:null
                                let barcode_role = scale?scale.barcode_role:null
                                let code_prefix = item_code.slice(0,prefix_size)

                                if (prefix===code_prefix) {
                                    item_code = ""+item_code
                                    let abc = item_code.slice(prefix_size)
                                    let c = this.toNumber(item_code.slice(-c_size) )
                                    let ab = abc.slice(0,-c_size)
                                    let a = 0
                                    let b = 0
                                    let bc = 0
                                    if (ab) {
                                        let cut_start = 0
                                        let first_number = 0
                                        while (first_number === 0) {
                                            first_number = this.toNumber(ab.slice(cut_start,cut_start+1))
                                            cut_start++
                                        }
                                        if (first_number) {
                                            a = this.toNumber(ab.slice(cut_start-1,cut_start+2))
                                        }
                                        if (a) {
                                            b = this.toNumber(ab.slice(cut_start+2))
                                        } 
                                    }

                                    if (a) {
                                        item_selected = items.find(item=>{
                                            return  this.toNumber(item.code) == a
                                        }) 
                                        retail_price = item_selected?item_selected.retail_price:null
                                        wholesale_price = item_selected?item_selected.wholesale_price:null
                                    }
                                    if (b||code_prefix) {
                                        bc = this.toNumber(b+"."+c) 
                                    }

                                    if (bc && item_selected ) { 
                                        let price = selling_model=='Retail Price'?retail_price:wholesale_price 
                                        if (barcode_role === "prefix_code_quantity") {
                                            quantity = bc
                                        } else {
                                            quantity = bc/this.toNumber(price) 
                                        }
                                        quantity = quantity.toFixed(3)
                                    }

                                    

                                    // console.log(item_selected,"item_selected....");
                                    // console.log(item_code,"Item_code....");
                                    // console.log(prefix,"prefix....");
                                    // console.log(a,"a....");
                                    // console.log(b,"b....");
                                    // console.log(ab,"ab....");
                                    // console.log(abc,"abc....");
                                    // console.log(bc,"bc....");
                                    // console.log(quantity,"quantity....");
                                    // console.log(c,"c....");
                                    // console.log(retail_price,"retail_price....");
                                    // console.log(wholesale_price,"wholesale_price....");
                                    
                                } 
                            });
                        }

                        // console.log(item_selected,'-------')
                        // console.log(item_code,'-------item_code')
                        // console.log(scales,'-------scalesea')

                        if (!item_selected) {
                            this.MBS.actions.dialog()
                            setTimeout(() => {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:'ITEM NOT FOUND',
                                    text:"Item ("+item_code+") not found. Make sure Items are loaded and you have refreshed.",
                                    btnYes:"OKAY",
                                    btnNo:"CREATE AS NEW ITEM",
                                    action:{
                                        code:PAGE_NAME+'=CREATE_AS_NEW_ITEM',
                                        item_code:item_code,
                                    }
                                }) 
                            }, 500);
                            return 
                        } 
                        
                        //check stock level
                        if (false) {
                            if (activate_stock_level) {
                                let stock_settings = this.CHECK_ITEM_STOCK_SETTINGS({
                                    item:item_selected,
                                    outlet_code:outlet_code,
                                    notify:true
                                })
                                let allow_sell = stock_settings?stock_settings.allow_sell:null 
                                if (!allow_sell) {
                                    return
                                } 
                            }
                        }


                        /// if array
                        let newItem = {
                            ...item_selected, 
                            selling_model:selling_model,
                            sold_price:selling_model=='Retail Price'?item_selected.retail_price:item_selected.wholesale_price, 
                            quantity:quantity?quantity:1,
                        }

                        
                        //display selected item
                        if (false) {
                            try {
                                let displayName = newItem.name
                                displayName = displayName.slice(0,12)
                                this.DISPLAY_TO_VFD({
                                    text:"ITEM: "+displayName,
                                    text2:"PRICE: MWK"+newItem.sold_price,
                                }) 
                            } catch (error) {   
                            }  
                        }
 
                        return {
                            item_key:item_key,
                            item_code:item_code,
                            duplicate_selling_item:duplicate_selling_item,
                            company_key:company_key,
                            selected_item:newItem,
                        }
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ANALYZE_AND_SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ADD_SELECTED_SELLING_ITEMS(payload){ 
                try {
                    let items = this.ItemsData
                    let already_selected_items = this.SELECTED_ITEMS
                    let item_key = payload?payload.item_key:null
                    let item_code = payload?payload.item_code:null
                    let company_key = payload?payload.company_key:null
                    let duplicate_selling_item = payload?payload.duplicate_selling_item:null
                    let selected_item = payload?payload.selected_item:null
                    if (!selected_item) {
                        let analyzed = this.ANALYZE_AND_SELECT_ITEM(item_key,item_code)
                        selected_item = analyzed?analyzed.selected_item:null
                        duplicate_selling_item = analyzed?analyzed.duplicate_selling_item:null
                    }
                    if (selected_item) {
                        const moment = this.MBS.date.moment
                        const selecting_list_key = moment?moment().valueOf():null
                        let save_item = {
                            ...selected_item,
                            _key:selected_item.key,
                            _code:selected_item.code,
                            key:selecting_list_key,
                            selecting_list_key:selecting_list_key,
                            quantity:this.toNumber(selected_item.quantity),
                        } 

                        this.ON_SELECT_ITEM_OPTIONS({
                            ...payload,
                            item:save_item
                        })
    
                        // if (duplicate_selling_item) {
                            
                        // }else{ 
                        //     let already_item = already_selected_items?already_selected_items.find(it=>it._key == selected_item.key):false
                        //     if (already_item) {
                        //         save_item = {
                        //             ...already_item, 
                        //             quantity:this.toNumber(already_item.quantity)+1
                        //         }  
                        //     } 
                        // }  
                        // DB.addItems({
                        //     data:save_item,
                        //     name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
                        //     // company_key:company_key,
                        //     update_locals:true,
                             
                        // })
                        
                    }else{  
                    }
                     
                   
                
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_SELECTED_SELLING_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_SELECTED_SELLING_ITEMS(item,selected_item_options){ 
                try {
                    
                    this.dialog_item_options = false
                    let already_selected_items = this.SELECTED_ITEMS
                    let payload = this.selected_item_payload
                    let item_key = payload?payload.item_key:null
                    let item_code = payload?payload.item_code:null
                    let company_key = payload?payload.company_key:null
                    let duplicate_selling_item = payload?payload.duplicate_selling_item:null
                    let selected_item = payload?payload.selected_item:null
                    let selected_item_option_names = ""
                    if (this.MBS.actions.SIZE(selected_item_options)) {
                        selected_item_options.forEach((item_option,index) => {
                            let divider = index == 0?"":", "
                            selected_item_option_names = selected_item_option_names+divider+item_option?.option_name 
                        });
                    }

                    if (item && payload) {  
                        let save_item =   {
                            ...item,
                            selected_item_option_names:selected_item_option_names,
                            selected_item_options:this.MBS.actions.SIZE(selected_item_options)?selected_item_options:null
                        } 
                        if (duplicate_selling_item) {
                            
                        }else{ 
                            let already_item = already_selected_items?
                                already_selected_items.find(it=>{
                                    let available = it._key == selected_item.key
                                    if (selected_item_option_names) {
                                       return available && it.selected_item_option_names == selected_item_option_names
                                 
                                    }
                                    return available
                                })
                            :false
                            if (already_item) {
                                save_item = {
                                    ...already_item, 
                                    quantity:this.toNumber(already_item.quantity)+1
                                }  
                            } 
                        } 
                        
                        DB.addItems({
                            data:save_item,
                            name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
                            // company_key:company_key,
                            update_locals:true,
                             
                        })
                        
                    }else{  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_SELECTED_SELLING_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            CREATE_SALE_ORDER(){
                try {

                    let selectedCompany = this.SELECTED_COMPANY 
                    let company_key = selectedCompany?selectedCompany.key:null
                    let localCashup = this.LocalCashup 
                    let cashup_key = localCashup?localCashup.key:null
                    let day_shift_key = localCashup?localCashup.day_shift_key:null
                 
                    
                    let TotalPrice = this.TotalPrice
                    let TotalDiscount = this.TotalDiscount
                    let TotalDiscountedPrice = this.TotalDiscountedPrice
                    let us = this.us
                    let uid = us?us.uid:null
                    let SELECTED_ITEMS = this.SELECTED_ITEMS
                    let items_size = this.MBS.actions.SIZE(SELECTED_ITEMS)
                    let moment = this.MBS.date.moment
                    let key =  moment?moment().valueOf():null
                    let CalculatedVAT = this.CalculatedVAT

                    if (items_size<1) { 
                    }
                    let order_details = {
                        sold:true,
                        created_uid:uid,
                        key:key,
                        cashup_key:cashup_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key, 
                        paid_amount:TotalDiscountedPrice,
                        total_price:TotalPrice,
                        total_discount:TotalDiscount,
                        local_created_at:key, 
                        order_type:DATA.ITEMS.SALE_ORDERS.value, 
                    }
                   
                    if (CalculatedVAT) {
                        order_details.calculated_vat = CalculatedVAT
                    }
                    let order_items = []

                    if (SELECTED_ITEMS) {
                        SELECTED_ITEMS.forEach(element => {
                            let average_cost_price = this.toNumber(element.average_cost_price)
                            let cost_price = this.toNumber(element.cost_price)
                            let sold_cost_price = average_cost_price?average_cost_price:cost_price
                            order_items.push({ 
                                sold_price:this.toNumber(element.sold_price),
                                sold_cost_price:sold_cost_price,
                                quantity:this.toNumber(element.quantity),
                                discount:this.toNumber(element.discount),
                                order_key:key,
                                selling_model:element.selling_model,
                                item_key:element._key?element._key:element.key,
                                key:element.key,
                                _key:element._key?element._key:element.key,
                            })
                        }); 
                    }
                    order_details.items = order_items

                   

                    // CHECK STOCK SETTINGS
                    // if (activate_stock_level) {
                    //     let stock_settings = this.CHECK_ITEMS_STOCK_SETTINGS({
                    //         items:SELECTED_ITEMS,
                    //         outlet_code:outlet_code,
                    //         notify:true
                    //     })
                    //     let stop_selling = stock_settings?stock_settings.stop_selling:null
                    //     if (stop_selling) {
                    //         return
                    //     }   
                    // }

                    return order_details
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SALE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //
            CHECK_ITEMS_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        stop_selling:false, 
                        ordering_remind:false, 
                        stock_settings:null,
                        stop_sell_items:null,
                        ordering_remind_items:null,
                        payload:payload
                    }  


                    let items = payload?payload.items:null  
                    let outlet_code = payload?payload.outlet_code:null   
                    let notify = payload?payload.notify:null   
                    if (!items) { 
                        return response
                    }

                    let stock_settings = []
                    let stop_sell_items = []
                    let ordering_remind_items = []
                    items.forEach(item => {
                        let stock_setting = this.CHECK_ITEM_STOCK_SETTINGS({
                            outlet_code:outlet_code,
                            item:item,
                            quantity:item.quantity, 
                        }) 
                        stock_settings.push(stock_setting)
                        if (stock_setting) {
                            if (!stock_setting.allow_sell) {
                               stop_sell_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                               response.stop_selling = true
                            }
                            if (stock_setting.ordering_remind) {
                                ordering_remind_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                                response.ordering_remind = true
                            }
                        }
                    });

                    if (notify) {
                        let stop_sell_size = this.MBS.actions.SIZE(stop_sell_items)
                        let ordering_remind_size = this.MBS.actions.SIZE(ordering_remind_items)
                        let stop_sell_names = ''
                        let ordering_remind_names = ''
                        stop_sell_items.forEach(item => {
                            stop_sell_names = stop_sell_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Stop selling level: "+item.stop_selling_level
                        });
                        ordering_remind_items.forEach(item => {
                            ordering_remind_names = ordering_remind_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Ordering level: "+item.ordering_remind_level
                        });

                        if (stop_sell_size&&ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names
                                    +" \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (stop_sell_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names  
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"LOW ON STOCK LEVEL",
                                text:"Some of the selected item(s) are below order remind level. \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }  
                    } 
                    return {
                        ...response, 
                        allow_sell:!response.stop_selling,
                        stock_settings:stock_settings,
                        stop_sell_items:stop_sell_items,
                        ordering_remind_items:ordering_remind_items,
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEMS_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CHECK_ITEM_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        allow_sell:false,
                        ordering_remind:false,
                        quantity:null,
                        stop_selling_level:null,
                        ordering_remind_level:null,
                        active_settings:null,
                        payload:payload
                    }    
                    let item_selected = payload?payload.item:null 
                    let item_selected_name = item_selected?item_selected.name:null 
                    let item_kits = item_selected?item_selected.item_kits:null 
                    let outlet_code = payload?payload.outlet_code:null  
                    let quantity = this.toNumber(payload?payload.quantity:null)
                    let notify = payload?payload.notify:null  
                    quantity = quantity?quantity:1
                    if (!item_selected) { 
                        return response
                    }
                    //
                    let sold_item = this.getSoldItem(item_selected.key)
                    let sold_quantity = this.toNumber(sold_item?sold_item.sold_quantity:null) 
                    let settings = this.CompanySettings
                    let stock_settings = settings?settings.stock_settings:null
                    let outlets_settings = stock_settings?stock_settings.outlets:null
                    let outlet_stock_settings = outlets_settings?outlets_settings[outlet_code]:null

                    let item_stock_settings = item_selected?item_selected.stock_settings:null 
                    let active_settings = item_stock_settings?item_stock_settings:outlet_stock_settings?outlet_stock_settings:stock_settings

                    response.active_settings = active_settings  

                    if (active_settings && item_selected) {
                        let stop_selling_level = this.toNumber(active_settings.stop_selling_level)
                        let ordering_remind_level = this.toNumber(active_settings.ordering_remind_level)
                        let item_quantity = this.toNumber(item_selected['number_quantity'])
                        if (outlet_code) {
                            item_quantity = this.toNumber(item_selected[outlet_code+'_number_quantity'])
                        }
                        item_quantity = item_quantity-sold_quantity
                        response.sold_quantity = sold_quantity
                        response.quantity = item_quantity
                        response.stop_selling_level = stop_selling_level
                        response.ordering_remind_level = ordering_remind_level
                        
                        if ((item_quantity-quantity) < stop_selling_level) {
                            response.allow_sell = false
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"OUT OF STOCK",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n STOP SELLING LEVEL: "+stop_selling_level
                                        +"\n \n   The selected item is below selling level.",
                                    btnYes:"OKAY"
                                })  
                            }
                        }else if ((item_quantity-quantity) < ordering_remind_level) {
                            response.allow_sell = true
                            response.ordering_remind = true
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"LOW ON STOCK LEVEL",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n ORDER REMIND LEVEL: "+ordering_remind_level
                                        +"\n \n   The selected item is below ordering level.", 
                                    btnYes:"OKAY"
                                }) 
                            }
                        }else{
                            response.allow_sell = true
                        }  

                        if (item_kits) { 
                            response.allow_sell = true
                        }
                    }
                    if (!active_settings) {
                        response.allow_sell = true
                    } 
                    return response
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //---------------[MAIN FUNCTIONS]------------  
            DIALOG_YES(action){ 
                try { 
                    if (action.code) {   
                        if (action.code ===PAGE_NAME+'=REMOVE-ITEM') {
                            if (action.YES) {
                                setTimeout(() => {   
                                    let company = this.SELECTED_COMPANY
                                    let company_key = company?company.company_key:null
                                    let user = action.ud
                                    let us_company = user?user.companies?user.companies[company_key]:null:null
                                    let company_user_group_code = us_company?us_company.company_user_group_code:null
                                    if (company_user_group_code == 1) {
                                        this.REMOVE_ITEM(action.data)
                                    }else{
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Access Denied!...",null,"OK") 
                                    } 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                         
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME,  
                    })   
                }
            }, 
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{  
                             
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+""+value 
                            }
                        }else if (field_value == "current_selected_item_discount") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.discount = (""+this.current_selected_item.discount).slice(0,-1)
                            } else {
                                this.current_selected_item.discount =this.current_selected_item.discount+""+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1); 
                        }
                        
                    }

                }
               
            },
            OUTPUT(){ 
                try {
                    let selected_items = this.SELECTED_ITEMS
                    let sale_order = this.CREATE_SALE_ORDER()
                    let checked_items = this.CheckedItems 
                    let selected_total_sold_price = 0
                    let checked_total_sold_price = 0
                    let item_size = 0

                    //calculate selected
                    if (selected_items) {
                        for (const item of selected_items) {
                            let sold_price = this.toNumber(item.sold_price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            selected_total_sold_price = selected_total_sold_price+((sold_price-discount)*quantity)
                            item_size++
                        } 
                    }
                    
                    //calculate checked
                    if (checked_items) {
                        for (const item of checked_items) {
                            let sold_price = this.toNumber(item.sold_price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            checked_total_sold_price = checked_total_sold_price+((sold_price-discount)*quantity)
                             
                        } 
                    }
                    this.$emit("input",{
                        selected_items:selected_items,
                        selected_total_sold_price:selected_total_sold_price,
                        selected_total_sold_price_:this.MBS.actions.money(selected_total_sold_price),
                        checked_items:checked_items,
                        checked_total_sold_price_:this.MBS.actions.money(checked_total_sold_price),
                        item_size:item_size,
                        sale_order:sale_order,
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OUTPUT',
                        page:PAGE_NAME, 
                    }) 
                }
            },  
            ON_KEY_DOWN(key,time){
                try { 
                     
                    if (key=='F1') { 
                    }  
                    if (key =='F5') {   
                    }else if (key =='F11') {   
                    }else if (key =='Escape') {   
                    }else if (key =='Shift') {   
                    }else if (key =='Control') {   
                    }else if (key =='Enter') {   
                        if (this.is_payment_cash_focused) {
                            this.$emit("SUBMIT",{
                                element:this.is_payment_cash_focused
                            })
                        } 
                    }else {
                         
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);    
                this.MBS.events.$off('ADD_SELECTED_SELLING_ITEMS', this.ADD_SELECTED_SELLING_ITEMS);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)
                this.OUTPUT()
            },
            SELECTED_ITEMS(value){
                this.OUTPUT()
            },
            LocalCashup(value){
                this.CLEAR_SALE_ORDERS(value)
            },
            LocalSelectedSalesItemsData(value){
                this.UPDATE_AUTO_TIP()
            },
            CurrentOutlet(value){
                this.UPDATE_AUTO_TIP()
            },
            is_payment_cash_focused(value){
                console.log('is_payment_cash_focused.....');
            },
        } 

    }
</script>
<style>
    .selected {
        background-color: #a8dadc;
    }
    .checked {
        background-color: #dadada;
    }
    
</style> 